<template>
    <div class="coupon">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    代金券
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='input_cont'>
            <van-field v-model="code" placeholder="请输入兑换码" clearable class='input_text' size='large' />
            <div class='submit_btn' @click="submitRedeem">
                兑换
            </div>
        </div>

        <div class='list_cont'>
            <div class='list' v-for='item in couponList' :key='item'>
                <div class='list_money'>
                    <span>¥</span>{{item.price}}
                </div>
                <div class='dero_cont'>
                    <div>
                        <div class='span'></div>{{item.remark}}</div>
                    <div>
                        <div class='span'></div>{{item.priceLimitDesc}}</div>
                    <div>
                        <div class='span'></div>{{item.endTimeDesc}}</div>
                </div>
            </div>
            <!-- <div class='list'>
                <div class='list_money'>
                    <span>¥</span>100
                </div>
                <div class='dero_cont'>
                    <div>
                        <div class='span'></div>仅用于护士上门订单</div>
                    <div>
                        <div class='span'></div>满189元才可使用</div>
                    <div>
                        <div class='span'></div>2021年12月31日到期</div>
                </div>
            </div> -->
        </div>

        <div class='no_coupon' v-if="count==0">
            <img src='https://static.yihu365.cn/img/h5Img/assets/img/no_coupon.png' class='no_img' />
            <div>您还没有代金券</div>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Dialog,Toast } from "vant";
import loginApi from "@axios/login";

export default {
  name: "coupon",
  components: {},
  data() {
    return {
      requestData: {
        function: "myVoucher",
        token: this.$store.state.token,
        userId: this.$store.state.uid,
        minRow: "1",
        maxRow: "100",
        getAccess: "",
        isEnable: "0",
        serviceCode: "",
        roleCode: "",
        orderId: "",
        serviceType: "",
        _from: "h5",
        version: "1.95",
        newversion: "95",
        source: "h5_users_002"
      },
      count:0,
      couponList:[],
      code : ''
    };
  },
  methods: {
    getCoupons(formData) {
      loginApi.loginAction(formData).then(res => {
          console.log(res);
          this.count = res.data.count;
          this.couponList = res.data.list

      });
    },
    submitRedeem(){
      let formData = new FormData();
      formData.append('data',JSON.stringify({
          function : 'voucherRedeem',
          token : this.$store.state.token,
          redeemCode : this.code,
          version : this.$store.state.version,
          newversion : this.$store.state.newversion,
          login_userId_base : this.$store.state.uid,
          sid : this.$store.state.sid,
          source : 'h5_users_002'
      }))

      formData.append('encryption',false)

      loginApi.loginAction(formData).then(res => {
        Toast('兑换成功！')
        setTimeout(()=> {
          let formData = new FormData();
          formData.append("data",JSON.stringify(this.requestData));
          formData.append("encryption", false);
          this.getCoupons(formData);
        },1000)

      });
    }
  },
  mounted() {

    let formData = new FormData();
    formData.append("data",JSON.stringify(this.requestData));
    formData.append("encryption", false);
    this.getCoupons(formData);

  },
  setup() {
    return {};
  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.no_coupon {
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 200px;

  img {
    width: 300px;
    margin-bottom: 25px;
  }
}
.list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 120px;
  overflow: hidden;
  .list_money {
    font-size: 70px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #ffffff;
    span {
      font-size: 30px;
      margin-right: 15px;
    }
  }
}
.dero_cont {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-left: 34px;
  padding-right:10px;
  & > div {
    position: relative;
  }
  .span {
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 6px;
    position: absolute;
    left: -20px;
    top: 10px;
  }
}
.coupon {
  background: #f0f0f0;
  height: 100%;
}
.input_cont {
  margin-top: 50px;
  width: 690px;
  margin-left: 30px;
  display: flex;
  .submit_btn {
    width: 105px;
    height: 92px;
    background: #00c291;
    line-height: 92px;
    text-align: center;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border-radius: 10px;
    margin-left: 15px;
    flex-shrink: 0;
  }
  .input_text {
    height: 92px;
    border-radius: 10px;
    ::v-deep(input) {
      height: 48px;
      font-size: 28px;
      padding-left: 22px;
    }
  }
}
.list_cont {
  padding: 0 30px;
  margin-top: 45px;
}
.list {
  margin-bottom: 20px;
  height: 160px;
  border-radius: 10px;
  background: url(https://static.yihu365.cn/img/h5Img/assets/img/my/coupon_bg.jpg) no-repeat center center;
  background-size: 100%;
}
</style>
