import { render } from "./coupon.vue?vue&type=template&id=31c515b4&scoped=true"
import script from "./coupon.vue?vue&type=script&lang=js"
export * from "./coupon.vue?vue&type=script&lang=js"

import "./coupon.vue?vue&type=style&index=0&id=31c515b4&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-31c515b4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "31c515b4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('31c515b4', script)) {
    api.reload('31c515b4', script)
  }
  
  module.hot.accept("./coupon.vue?vue&type=template&id=31c515b4&scoped=true", () => {
    api.rerender('31c515b4', render)
  })

}

script.__file = "src/views/my/coupon/coupon.vue"

export default script